// extracted by mini-css-extract-plugin
export var bannerBtn = "IgniteInnovation2-module--bannerBtn--fe2af";
export var btn_white1_border = "IgniteInnovation2-module--btn_white1_border--0245c";
export var card = "IgniteInnovation2-module--card--b351a";
export var cardWrapper = "IgniteInnovation2-module--cardWrapper--75ee0";
export var choseImg = "IgniteInnovation2-module--choseImg--6b33f";
export var description = "IgniteInnovation2-module--description--a8fbb";
export var heading = "IgniteInnovation2-module--heading--d6730";
export var ignite = "IgniteInnovation2-module--ignite--ac8eb";
export var ignite2 = "IgniteInnovation2-module--ignite2--24f50";
export var subservice = "IgniteInnovation2-module--subservice--d3b61";
export var teamBtn = "IgniteInnovation2-module--teamBtn--82cf3";
export var title = "IgniteInnovation2-module--title--c43a6";
export var title2 = "IgniteInnovation2-module--title2--ae00b";