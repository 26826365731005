// extracted by mini-css-extract-plugin
export var bannerBtn = "HireAI-module--bannerBtn--2156e";
export var bannerBtn2 = "HireAI-module--bannerBtn2--691c5";
export var btn_white1_border = "HireAI-module--btn_white1_border--157b2";
export var card = "HireAI-module--card--4b81d";
export var cardData = "HireAI-module--cardData--c3027";
export var cards = "HireAI-module--cards--e55c0";
export var description = "HireAI-module--description--f5f1d";
export var hire = "HireAI-module--hire--bb7b7";
export var hireCyber = "HireAI-module--hireCyber--d29c1";
export var hireImg = "HireAI-module--hireImg--6c430";
export var talent = "HireAI-module--talent--d3d79";
export var tick = "HireAI-module--tick--5e57b";