import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import tick from "../../images/cyberSecurity/tick.png"
import * as styles from "./HireAI.module.scss"

const HireAI = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.hireCyber}>
      <Container>
        <Row className="gap-30">
          <Col
            xl={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className={styles.hire}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={"Hire React Native App Developers To Craft Seamless Experiences"}
                  decoding="async"
                  loading="lazy"
                />
              )}
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.bannerBtn}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white1_border}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className={styles.cards}>
              <div>
                <Row className={styles.card}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((item, index) => (
                      <div className={styles.cardData}>
                        <div className={styles.talent} key={index}>
                          <img
                            className={styles.tick}
                            src={tick}
                            alt={item?.title}
                          />
                          <h3>{item?.title}</h3>
                        </div>
                      </div>
                    ))}
                </Row>
              </div>
            </div>
            <div className={styles.bannerBtn2}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white1_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HireAI
