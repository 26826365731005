import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, breadCrumb }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-white">
            Home
          </Link>
          {" » "}
          {breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-white">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-white">{val?.title}</span>
            )
          )}
        </nav>
        <Row>
          <Col xl={6} lg={12}>
            <div>
              <div className={styles.heading}>
                <h1
                  className={styles.bannerHeading}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
              </div>
              <div className={`${styles.bannerBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white2_border}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
                c
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
                className={styles.banerImg}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
