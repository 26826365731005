// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--fafb5";
export var clientDots2 = "Testimonials-module--clientDots2--5607c";
export var content = "Testimonials-module--content--ca83c";
export var dots3 = "Testimonials-module--dots3--60041";
export var hireArrow = "Testimonials-module--hireArrow--d3eb3";
export var iconContainer = "Testimonials-module--iconContainer--fd403";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--c2078";
export var imgBtn = "Testimonials-module--imgBtn--8a269";
export var person = "Testimonials-module--person--7f1ff";
export var personBg = "Testimonials-module--personBg--6acf9";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--dd264";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--ceda6";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--804af";
export var row = "Testimonials-module--row--82487";
export var section = "Testimonials-module--section--70b24";
export var staffRec = "Testimonials-module--staffRec--1ca9b";
export var testName = "Testimonials-module--testName--3d64d";
export var testimonials = "Testimonials-module--testimonials--02323";
export var testimonialsContent = "Testimonials-module--testimonialsContent--0388e";
export var vector2 = "Testimonials-module--vector2--121d6";