// extracted by mini-css-extract-plugin
export var Group = "DriveSuccess-module--Group--8d5f1";
export var Vector = "DriveSuccess-module--Vector--a0067";
export var card = "DriveSuccess-module--card--c365d";
export var cardWrapper = "DriveSuccess-module--cardWrapper--d81ec";
export var choseImg = "DriveSuccess-module--choseImg--0a697";
export var cir = "DriveSuccess-module--cir--8c8c0";
export var description = "DriveSuccess-module--description--814a9";
export var dots1 = "DriveSuccess-module--dots1--6aec9";
export var dots2 = "DriveSuccess-module--dots2--25784";
export var dots4 = "DriveSuccess-module--dots4--f1439";
export var dotscard = "DriveSuccess-module--dotscard--dd1e1";
export var heading = "DriveSuccess-module--heading--8b89e";
export var heading2 = "DriveSuccess-module--heading2--89ea2";
export var react = "DriveSuccess-module--react--4a6ae";
export var recc = "DriveSuccess-module--recc--9f5bb";
export var subservice = "DriveSuccess-module--subservice--d08cf";
export var teamBtn = "DriveSuccess-module--teamBtn--3a086";