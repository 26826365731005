import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import EngagementModels from "../../components/common/Engagement-Model/EngagementModels"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Testimonials2 from "../../components/healthcare/Testimonials2"
import Banner from "../../components/react-native/Banner"
import DiscoverInvozone from "../../components/react-native/DiscoverInvozone"
import DriveSuccess from "../../components/react-native/DriveSuccess"
import Firsthand from "../../components/react-native/Firsthand"
import HireAI from "../../components/react-native/HireAI"
import IgniteInnovation from "../../components/react-native/IgniteInnovation"
import IgniteInnovation2 from "../../components/react-native/IgniteInnovation2"
import Ready from "../../components/react-native/Ready"
import Testimonials from "../../components/react-native/Testimonials"
import Toolkit from "../../components/react-native/Toolkit"
import WhatSets from "../../components/react-native/WhatSets"
import WorkPortfolio from "../../components/common/WorkPortfolio"
import MainLayout from "../../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
    />
  )
}
const reactNative = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1281) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1281)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const drive = data?.strapiPage?.sections[1]
  const discover = data?.strapiPage?.sections[2]
  const ready = data?.strapiPage?.sections[3]
  const ignite = data?.strapiPage?.sections[4]
  const what = data?.strapiPage?.sections[5]
  const get = data?.strapiPage?.sections[6]
  const hire = data?.strapiPage?.sections[7]
  const opmize = data?.strapiPage?.sections[8]
  const elevate = data?.strapiPage?.sections[9]
  const testimonials = data?.strapiPage?.sections[10]
  const faqs = data?.strapiPage?.sections[11]
  const ignite2 = data?.strapiPage?.sections[12]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <DriveSuccess strapiData={drive} />
      <DiscoverInvozone strapiData={discover} />
      <Ready strapiData={ready} />
      {!isMobile && !isTablet ? (
        <IgniteInnovation strapiData={ignite} />
      ) : (
        <IgniteInnovation2 strapiData={ignite2} />
      )}
      <WhatSets strapiData={what} />
      <Firsthand strapiData={get} pageName="React Native App Development" />
      <HireAI strapiData={hire} />
      <EngagementModels strapiData={opmize} />
      <Toolkit strapiData={elevate} />
      {/* <WorkPortfolio /> */}
      <WorkPortfolio
        portfolioHead={"Our Portfolio Gallery"}
        portfolioDesc={
          "Explore our impressive portfolio showcase and witness the excellence of our work firsthand."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="React Native App Development" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "react-native" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default reactNative
