// extracted by mini-css-extract-plugin
export var Ideation = "IgniteInnovation-module--Ideation--1deb8";
export var bannerBtn = "IgniteInnovation-module--bannerBtn--6921e";
export var btn_white1_border = "IgniteInnovation-module--btn_white1_border--daefe";
export var cards = "IgniteInnovation-module--cards--df8cd";
export var cards2 = "IgniteInnovation-module--cards2--16546";
export var cardsData = "IgniteInnovation-module--cardsData--91eb7";
export var closeUp = "IgniteInnovation-module--closeUp--21b58";
export var dec = "IgniteInnovation-module--dec--5a33a";
export var heading = "IgniteInnovation-module--heading--8fdc2";
export var leftText = "IgniteInnovation-module--leftText--a90cb";
export var mobBg = "IgniteInnovation-module--mobBg--93e12";
export var number = "IgniteInnovation-module--number--3547a";
export var number2 = "IgniteInnovation-module--number2--75e89";
export var peakNumber = "IgniteInnovation-module--peakNumber--64bc2";
export var peakNumber2 = "IgniteInnovation-module--peakNumber2--011e2";
export var research = "IgniteInnovation-module--research--a5a1b";
export var rightText = "IgniteInnovation-module--rightText--4c18a";
export var serial2 = "IgniteInnovation-module--serial2--73685";
export var step = "IgniteInnovation-module--step--d26bf";