import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./DiscoverInvozone.module.scss"

const DiscoverInvozone = ({ strapiData }) => {
  return (
    <>
      <div className={`${styles.subservice}`}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col xl={6} lg={12} key={i}>
                  <div className={styles.card}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default DiscoverInvozone
