import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./IgniteInnovation2.module.scss"

const IgniteInnovation2 = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.ignite}>
          {image ? (
            <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              image={image}
              alt={strapiData?.title}
            />
          ) : (
            <img
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={strapiData?.title}
              decoding="async"
              loading="lazy"
            />
          )}
        </div>
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  decoding="async"
                  loading="lazy"
                  alt={e?.image1[0]?.alternativeText}
                  className={styles.ignite2}
                />
                <span>0{i + 1}</span>
                <h3 className={styles.title}>{e?.title}</h3>
                <p className={styles.title2}>{e?.title2}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white1_border}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default IgniteInnovation2
