// extracted by mini-css-extract-plugin
export var SliderWidth = "WhatSets-module--SliderWidth--400aa";
export var arrowImg = "WhatSets-module--arrowImg--02d7e";
export var card = "WhatSets-module--card--ff1df";
export var cardWrapper = "WhatSets-module--cardWrapper--e23af";
export var cards = "WhatSets-module--cards--b780e";
export var description = "WhatSets-module--description--0ee80";
export var heading = "WhatSets-module--heading--7e588";
export var headingPremium = "WhatSets-module--headingPremium--cec90";
export var premiumBg = "WhatSets-module--premiumBg--971a9";
export var premiumImg = "WhatSets-module--premiumImg--652d2";
export var providingImg = "WhatSets-module--providingImg--3769a";
export var teamButton = "WhatSets-module--teamButton--b2c0a";
export var teamWork = "WhatSets-module--teamWork--626a4";
export var trailBg = "WhatSets-module--trailBg--f6bf9";